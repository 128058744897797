import React, { useState, useEffect } from 'react';
import './Filters.css';

// Import feature images
import beerGardenImage from '../assets/icons/standard/beerGarden.png';
import beerGardenPrefImage from '../assets/icons/animation/beerGarden.png';
import dogFriendlyImage from '../assets/icons/standard/dogFriendly.png';
import dogFriendlyPrefImage from '../assets/icons/animation/dogFriendly.png';
import liveMusicImage from '../assets/icons/standard/liveMusic.png';
import liveMusicPrefImage from '../assets/icons/animation/liveMusic.png';
import poolTableImage from '../assets/icons/standard/poolTable.png';
import poolTablePrefImage from '../assets/icons/animation/poolTable.png';
import pubFoodImage from '../assets/icons/standard/pubFood.png';
import pubFoodPrefImage from '../assets/icons/animation/pubFood.png';
import realAleImage from '../assets/icons/standard/realAle.png';
import realAlePrefImage from '../assets/icons/animation/realAle.png';
import dartsImage from '../assets/icons/standard/darts.png';
import dartsPrefImage from '../assets/icons/animation/darts.png';
import disabledAccessImage from '../assets/icons/standard/disabledAccess.png';
import disabledAccessPrefImage from '../assets/icons/animation/disabledAccess.png';
import pubQuizImage from '../assets/icons/standard/pubQuiz.png';
import pubQuizPrefImage from '../assets/icons/animation/pubQuiz.png';
import sundayRoastImage from '../assets/icons/standard/sundayRoast.png';
import sundayRoastPrefImage from '../assets/icons/animation/sundayRoast.png';
import btSportsImage from '../assets/icons/standard/btSports.png';
import btSportsPrefImage from '../assets/icons/animation/btSports.png';
import skySportsImage from '../assets/icons/standard/skySports.png';
import skySportsPrefImage from '../assets/icons/animation/skySports.png';
import parkingImage from '../assets/icons/standard/parking.png';
import parkingPrefImage from '../assets/icons/animation/parking.png';
import accommodationImage from '../assets/icons/standard/accommodation.png';
import accommodationPrefImage from '../assets/icons/animation/accommodation.png';
import nearCampsiteImage from '../assets/icons/standard/nearCampsite.png';
import nearCampsitePrefImage from '../assets/icons/animation/nearCampsite.png';
import scenicImage from '../assets/icons/standard/scenic.png';
import scenicPrefImage from '../assets/icons/animation/scenic.png';
import cocktailsImage from '../assets/icons/standard/cocktails.png';
import cocktailsPrefImage from '../assets/icons/animation/cocktails.png';
import playAreaImage from '../assets/icons/standard/playArea.png';
import playAreaPrefImage from '../assets/icons/animation/playArea.png';
import functionRoomImage from '../assets/icons/standard/functionRoom.png';
import functionRoomPrefImage from '../assets/icons/animation/functionRoom.png';
import takeawayAvailableImage from '../assets/icons/standard/takeawayAvailable.png';
import takeawayAvailablePrefImage from '../assets/icons/animation/takeawayAvailable.png';
import glutenFreeFoodImage from '../assets/icons/standard/glutenFreeFood.png';
import glutenFreeFoodPrefImage from '../assets/icons/animation/glutenFreeFood.png';
import veganImage from '../assets/icons/standard/vegan.png';
import veganPrefImage from '../assets/icons/animation/vegan.png';
import vegetarianImage from '../assets/icons/standard/vegetarian.png';
import vegetarianPrefImage from '../assets/icons/animation/vegetarian.png';
import nearbyStationImage from '../assets/icons/standard/nearbyStation.png';
import nearbyStationPrefImage from '../assets/icons/animation/nearbyStation.png';
import caskAleImage from '../assets/icons/standard/caskAle.png';
import caskAlePrefImage from '../assets/icons/animation/caskAle.png';
import lgbtqPlusImage from '../assets/icons/standard/lgbtqPlus.png';
import lgbtqPlusPrefImage from '../assets/icons/animation/lgbtqPlus.png';
import studentOffersImage from '../assets/icons/standard/studentOffers.png';
import studentOffersPrefImage from '../assets/icons/animation/studentOffers.png';
import microPubImage from '../assets/icons/standard/microPub.png';
import microPubPrefImage from '../assets/icons/animation/microPub.png';
import happyHourImage from '../assets/icons/standard/happyHour.png';
import happyHourPrefImage from '../assets/icons/animation/happyHour.png';
import boardGamesImage from '../assets/icons/standard/boardGames.png';
import boardGamesPrefImage from '../assets/icons/animation/boardGames.png';

const featureImages = {
    beerGarden: { standard: beerGardenImage, pref: beerGardenPrefImage },
    dogFriendly: { standard: dogFriendlyImage, pref: dogFriendlyPrefImage },
    liveMusic: { standard: liveMusicImage, pref: liveMusicPrefImage },
    poolTable: { standard: poolTableImage, pref: poolTablePrefImage },
    pubFood: { standard: pubFoodImage, pref: pubFoodPrefImage },
    realAle: { standard: realAleImage, pref: realAlePrefImage },
    darts: { standard: dartsImage, pref: dartsPrefImage },
    disabledAccess: { standard: disabledAccessImage, pref: disabledAccessPrefImage },
    pubQuiz: { standard: pubQuizImage, pref: pubQuizPrefImage },
    sundayRoast: { standard: sundayRoastImage, pref: sundayRoastPrefImage },
    btSports: { standard: btSportsImage, pref: btSportsPrefImage },
    skySports: { standard: skySportsImage, pref: skySportsPrefImage },
    parking: { standard: parkingImage, pref: parkingPrefImage },
    accommodation: { standard: accommodationImage, pref: accommodationPrefImage },
    nearCampsite: { standard: nearCampsiteImage, pref: nearCampsitePrefImage },
    scenic: { standard: scenicImage, pref: scenicPrefImage },
    cocktails: { standard: cocktailsImage, pref: cocktailsPrefImage },
    playArea: { standard: playAreaImage, pref: playAreaPrefImage },
    functionRoom: { standard: functionRoomImage, pref: functionRoomPrefImage },
    takeawayAvailable: { standard: takeawayAvailableImage, pref: takeawayAvailablePrefImage },
    glutenFreeFood: { standard: glutenFreeFoodImage, pref: glutenFreeFoodPrefImage },
    vegan: { standard: veganImage, pref: veganPrefImage },
    vegetarian: { standard: vegetarianImage, pref: vegetarianPrefImage },
    nearbyStation: { standard: nearbyStationImage, pref: nearbyStationPrefImage },
    caskAle: { standard: caskAleImage, pref: caskAlePrefImage },
    lgbtqPlus: { standard: lgbtqPlusImage, pref: lgbtqPlusPrefImage },
    studentOffers: { standard: studentOffersImage, pref: studentOffersPrefImage },
    microPub: { standard: microPubImage, pref: microPubPrefImage },
    happyHour: { standard: happyHourImage, pref: happyHourPrefImage },
    boardGames: { standard: boardGamesImage, pref: boardGamesPrefImage },
};

const Filters = ({ onFilter }) => {
    const [location, setLocation] = useState('');
    const [features, setFeatures] = useState({});
    const [radius, setRadius] = useState(10);
    const [locationCoords, setLocationCoords] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [showFilters, setShowFilters] = useState(false);

    const handleLocationChange = (e) => {
        const searchQuery = e.target.value;
        setLocation(searchQuery);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(setTimeout(async () => {
            if (searchQuery.length > 2) {
                try {
                    const response = await fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${searchQuery}&proximity=ip&access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`);
                    const data = await response.json();
                    if (data.features && data.features.length > 0) {
                        setSuggestions(data.features);
                    }
                } catch (error) {
                    console.error('Error fetching location data:', error);
                }
            } else {
                setSuggestions([]);
            }
        }, 750));
    };

    const handleSuggestionClick = (suggestion) => {
        const place_name = suggestion.properties.full_address;
        const latitude = suggestion.geometry.coordinates[1];
        const longitude = suggestion.geometry.coordinates[0];
        setLocation(place_name);
        setLocationCoords({ latitude: latitude, longitude: longitude });
        setSuggestions([]);
    };

    const handleFeatureChange = (feature) => {
        console.log(feature)
        setFeatures((prevFeatures) => ({
            ...prevFeatures,
            [feature]: !prevFeatures[feature]
        }));
    };

    const handleFilter = () => {
        onFilter({ locationCoords, features, radius });
    };

    return (
        <div className="filters-wrapper">
            <button onClick={() => setShowFilters(!showFilters)} className="toggle-filters-button">
                {showFilters ? 'Hide Filters' : 'Show Filters'}
            </button>
            {showFilters && (
                <div className="filters animated fadeIn">
                    <input
                        type="text"
                        value={location}
                        onChange={handleLocationChange}
                        placeholder="Search by city, county, or postcode"
                        className="location-input modern-input"
                    />
                    {suggestions.length > 0 && (
                        <ul className="suggestions-list">
                            {suggestions.map((suggestion, index) => (
                                <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                    {suggestion.properties.full_address}
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className="radius-slider">
                        <label htmlFor="radius">Radius: {radius} miles</label>
                        <input
                            type="range"
                            id="radius"
                            min="1"
                            max="50"
                            value={radius}
                            onChange={(e) => setRadius(e.target.value)}
                            className="modern-slider"
                        />
                    </div>
                    <div className="features-container">
                        {Object.keys(featureImages).map((feature) => (
                            <div
                                key={feature}
                                className="feature-checkbox"
                                onClick={() => handleFeatureChange(feature)}
                            >
                                <img
                                    src={features[feature] ? featureImages[feature].pref : featureImages[feature].standard}
                                    alt={feature}
                                    className="feature-icon"
                                    onError={(e) => { e.target.onerror = null; e.target.src = featureImages[feature].standard; }}
                                />
                            </div>
                        ))}
                    </div>
                    <button onClick={handleFilter} className="filter-button">Apply Filters</button>
                </div>
            )}
        </div>
    );
};

export default Filters;
