import React from 'react';
import './GeneralInfo.css';

const GeneralInfo = ({ userData }) => {
    return (
        <div className="general-info">
            <h1>Hello, {userData.firstName}</h1>
            <hr />
            <p>First Name: {userData.firstName}</p>
            <p>Last Name: {userData.lastName}</p>
            <p>Username: {userData.username} (cannot be changed)</p>
            <p>Email: {userData.email}</p>
            <p>Phone: {userData.phone}</p>
            <p>Website: {userData.website}</p>
            <hr />
            <p>Street Address: {userData.streetAddress}</p>
            <p>City: {userData.city}</p>
            <p>State/County/Province: {userData.state}</p>
            <p>Country: {userData.country}</p>
            <p>Postal Code: {userData.postalCode}</p>
        </div>
    );
};

export default GeneralInfo;
