import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Claim.css';

const Claim = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { isAuthenticated, userRole } = useAuth();
    const [agree, setAgree] = useState(false);

    const handleClaim = async () => {
        if (!agree) return;

        const response = await fetch(`https://api.timeatthebar.co.uk/claim-venue/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        });

        if (response.ok) {
            navigate(`/venue/${id}`);
        }
    };

    if (!isAuthenticated || userRole === 'guest') {
        navigate('/register', { state: { from: `/claim/${id}` } });
        return null;
    }

    return (
        <div className="claim">
            <h1>Claim Venue</h1>
            <p>By claiming this venue, you acknowledge that you are the rightful owner and understand the legal implications of false claims.</p>
            <label>
                <input type="checkbox" checked={agree} onChange={() => setAgree(!agree)} />
                I agree to the terms and conditions
            </label>
            <button onClick={handleClaim} disabled={!agree}>Claim</button>
        </div>
    );
};

export default Claim;
