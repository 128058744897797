import React, { useState } from 'react';
import './AccountSettings.css';

const AccountSettings = ({ userId, logout }) => {
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [keepData, setKeepData] = useState(false);

    const handleAccountDeletion = async () => {
        if (deleteConfirm) {
            try {
                const response = await fetch('https://api.timeatthebar.co.uk/deleteUser', {
                    method: 'POST',
                    body: {
                        userId,
                        keepData,
                    },
                });
                const isDeleted = await response.json();
                if (isDeleted.result === 'success') {
                    alert('Account deleted successfully!');
                    logout();
                } else {
                    console.log(isDeleted);
                }
            } catch (error) {
                console.error('Failed to delete account:', error);
            }
        }
    };

    return (
        <div className="account-settings">
            <h1>The Important Parts</h1>
            <button onClick={() => setDeleteConfirm(true)} className="delete-button">Delete My Account</button>
            {deleteConfirm && (
                <div className="delete-confirmation">
                    <label>
                        <input type="checkbox" checked={keepData} onChange={() => setKeepData(!keepData)} />
                        Delete all data
                    </label>
                    <button onClick={handleAccountDeletion} className="confirm-delete-button">Confirm Deletion</button>
                </div>
            )}
        </div>
    );
};

export default AccountSettings;
