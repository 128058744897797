import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import IsLoggedInRoute from './components/IsLoggedInRoute';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Pricing from './pages/Pricing';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import MyAccount from './pages/MyAccount';
import Listings from './pages/Listings';
import VenueDetail from './pages/VenueDetail';
import Claim from './pages/Claim';
import Favourites from './pages/Favourites';
import AutomatedBrowser from './pages/AutomatedBrowser';
import Maintenance from './pages/Maintenance'; // Import the maintenance page

// Maintenance mode flag
const isMaintenanceMode = false;

(function () {
  console.log(`
  %c© 2024 Time at the bar. All rights reserved.
  Unauthorized copying of any files or scripts, via any medium is strictly prohibited.
  Proprietary and confidential.

  Author: Data Menu Limited c/o INFL8 Limited
  `, 'color: green; font-size: 16px; font-weight: bold;');
})();

const AppContent = () => {
  const { isAuthenticated } = useAuth();
  const [showMaintenance, setShowMaintenance] = useState(false);

  useEffect(() => {
    if (isMaintenanceMode && !isAuthenticated) {
      setShowMaintenance(true);
    } else {
      setShowMaintenance(false);
    }
  }, [isAuthenticated]);

  if (showMaintenance) {
    return <Maintenance />;
  }

  return (
    <>
      <Header />
      <Routes>
        {/* Redirects */}
        <Route path="/bars" element={<Navigate to={'/find-a-pub'} />} />

        {/* Security Pages */}
        <Route path="/automated-browser" element={<AutomatedBrowser />} />

        {/* Core Pages */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/pricing" element={<Pricing />} />

        {/* Listing Pages */}
        <Route path="/find-a-pub" element={<Listings />} />
        <Route path="/claim" element={<Claim />} />
        <Route path="/favourites" element={<Favourites />} />
        <Route path="/venue/:id" element={<VenueDetail />} />

        {/* Authentication Required */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-account"
          element={
            <ProtectedRoute>
              <MyAccount />
            </ProtectedRoute>
          }
        />
        <Route path="/register" element={<Register />} />
        <Route
          path="/login"
          element={
            <IsLoggedInRoute>
              <Login />
            </IsLoggedInRoute>
          }
        />
      </Routes>
      <Footer />
    </>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <AppContent />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
