import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './PricingCard.css';

const PricingCard = ({ plan, price, features, buttonText, buttonAction, isExternal }) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (isExternal) {
            window.location.href = buttonAction;
        } else {
            navigate(buttonAction);
        }
    };

    return (
        <div className="pricing-card">
            <h3>{plan}</h3>
            <p>{price}</p>
            <ul>
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
            <button onClick={handleButtonClick}>{buttonText}</button>
        </div>
    );
};

PricingCard.propTypes = {
    plan: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonAction: PropTypes.string.isRequired,
    isExternal: PropTypes.bool
};

PricingCard.defaultProps = {
    isExternal: false
};

export default PricingCard;
