import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Register.css';

const Register = () => {
    const { login } = useAuth();
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        username: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'UK',
        gender: 'not specified',
        phone: '',
        website: '',
        type: 'pubgoer'  // default to 'pubgoer'
    });

    const [errors, setErrors] = useState([]);
    const [error, setError] = useState('');

    const handleInputChange = (event) => {
        setCredentials({
            ...credentials,
            [event.target.name]: event.target.value
        });
    };

    const handleRoleChange = (role) => {
        setCredentials({
            ...credentials,
            type: role
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        const endpoint = 'https://api.timeatthebar.co.uk/register';

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials)
        });

        const data = await response.json();

        if (response) {
            if (data.error) {
                setErrors(data.error);
            } else {
                console.log(data);
                login(data.session_token, data.user_id);
                navigate('/')
            }
        } else {
            setError(data.error || 'An error occurred');
        }
    };

    return (
        <div className="register-container">
            <form className='login-form' onSubmit={handleSubmit}>
                <label>
                    Email:
                    <input
                        type="text"
                        name="email"
                        value={credentials.email}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    Phone Number:
                    <input
                        type="text"
                        name="phone"
                        value={credentials.phone}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    Website:
                    <input
                        type="text"
                        name="website"
                        value={credentials.website}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    Username:
                    <input
                        type="text"
                        name="username"
                        value={credentials.username}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    Password:
                    <input
                        type="password"
                        name="password"
                        value={credentials.password}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <hr />
                <label>
                    First Name:
                    <input
                        required
                        type="text"
                        name="firstName"
                        value={credentials.firstName}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    Last Name:
                    <input
                        required
                        type="text"
                        name="lastName"
                        value={credentials.lastName}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    Gender:
                    <select
                        required
                        name="gender"
                        value={credentials.gender}
                        onChange={handleInputChange}
                        className="input-field"
                    >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                        <option value="not specified">Prefer Not To Say</option>
                    </select>
                </label>
                <hr />
                <label>
                    Street Address:
                    <input
                        required
                        type="text"
                        name="streetAddress"
                        value={credentials.streetAddress}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    City:
                    <input
                        required
                        type="text"
                        name="city"
                        value={credentials.city}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    State/County/Province:
                    <input
                        required
                        type="text"
                        name="state"
                        value={credentials.state}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    Postal Code/ZIP:
                    <input
                        required
                        type="text"
                        name="postalCode"
                        value={credentials.postalCode}
                        onChange={handleInputChange}
                        className="input-field"
                    />
                </label>
                <label>
                    Country:
                    <select
                        required
                        name="country"
                        value={credentials.country}
                        onChange={handleInputChange}
                        className="input-field"
                    >
                        <option value="UK">UK</option>
                    </select>
                </label>
                <div className="role-selection">
                    <button type="button" className={`role-button ${credentials.type === 'pubgoer' ? 'active' : ''}`} onClick={() => handleRoleChange('pubgoer')}>Pub-Goer</button>
                    <button type="button" className={`role-button ${credentials.type === 'host' ? 'active' : ''}`} onClick={() => handleRoleChange('host')}>Venue Owner</button>
                </div>
                {errors.length > 0 && (
                    <div className="error-container">
                        {errors.map((error, index) => (
                            <p key={index} className="error-message">{error}</p>
                        ))}
                    </div>
                )}
                <button type="submit" className="login-button">Sign Up</button>
            </form>
            <p>Already with us? <a href="/login" className="link">Login here</a></p>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default Register;
