import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState('guest');
    const [user, setUser] = useState(null);

    const fetchUserRole = async () => {
        const token = localStorage.getItem('authToken');
        const userId = localStorage.getItem('userId');

        if (token) {
            const response = await fetch('https://api.timeatthebar.co.uk/get-role', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "uid": userId
                },
                method: 'POST'
            });
            if (response.ok) {
                const data = await response.json();
                setUserRole(data.role);
                setUser(data.user);
                localStorage.setItem('favourites', data.user.favourites);
                localStorage.setItem('drinkingAt', data.user.drinkingAt);
                console.log(data.role);
            } else {
                console.log('error');
            }
        }
    };

    // Function to log in the user
    const login = (token, uid) => {
        localStorage.setItem('authToken', token);
        localStorage.setItem('userId', uid);
        setIsAuthenticated(true);
        fetchUserRole();  // Call fetchUserRole after setting auth token and userId
    };

    // Function to log out the user
    const logout = () => {
        localStorage.removeItem('authToken');  // Remove the token from localStorage
        localStorage.removeItem('userId');  // Remove the userId from localStorage
        localStorage.removeItem('favourites');
        localStorage.removeItem('drinkingAt');
        setIsAuthenticated(false);
        setUserRole('guest');
        setUser(null);
    };

    // Effect to check auth token on initial load
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            const now = Date.now() / 1000;
            if (decodedToken.exp < now) {
                logout();
            } else {
                setIsAuthenticated(true);
                fetchUserRole();
            }
        }
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, userRole, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
