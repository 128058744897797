import React from 'react';
import PropTypes from 'prop-types';
import './FeatureBlock.css';

const FeatureBlock = ({ icon, title, body }) => {
    return (
        <div className="feature-block">
            <i className={`fa ${icon} feature-icon`}></i>
            <h3>{title}</h3>
            <p>{body}</p>
        </div>
    );
};

FeatureBlock.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
};

export default FeatureBlock;
