import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './Reviews.css';

const Reviews = ({ venueId }) => {
    const [reviews, setReviews] = useState([]);
    const { isAuthenticated, user } = useAuth();
    const [newReview, setNewReview] = useState({ title: '', rating: 1, content: '', first_name: '', last_name: '' });
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const reviewsPerPage = 3;

    useEffect(() => {
        const fetchReviews = async () => {
            const response = await fetch(`https://api.timeatthebar.co.uk/get-reviews/${venueId}?page=${page}&limit=${reviewsPerPage}`);
            const data = await response.json();
            setReviews((prevReviews) => {
                // Remove duplicates by checking the ID
                const newReviews = data.reviews.filter(review => !prevReviews.some(prevReview => prevReview._id === review._id));
                return [...prevReviews, ...newReviews];
            });
        };
        fetchReviews();
    }, [page, venueId]);

    useEffect(() => {
        if (isAuthenticated && user) {
            setNewReview({ ...newReview, first_name: user.firstName, last_name: user.lastName });
        }
    }, [isAuthenticated, user]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewReview({ ...newReview, [name]: value });
    };

    const handleStarClick = (rating) => {
        setNewReview({ ...newReview, rating });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isAuthenticated) return;

        setLoading(true);

        const response = await fetch('https://api.timeatthebar.co.uk/add-review', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                ...newReview,
                venue_id: venueId 
            })
        });

        setLoading(false);

        if (response.ok) {
            const review = await response.json();
            setReviews((prevReviews) => {
                // Add the new review only if it doesn't already exist
                if (!prevReviews.some(prevReview => prevReview._id === review._id)) {
                    return [...prevReviews, review];
                }
                return prevReviews;
            });
            setNewReview({ title: '', rating: 1, content: '', first_name: user.firstName, last_name: user.lastName });
        }
    };

    const renderStars = (rating, handleClick) => {
        return (
            <div className="stars">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <span
                            key={index}
                            className={index <= rating ? 'fa fa-star checked' : 'fa fa-star'}
                            onClick={() => handleClick(index)}
                        ></span>
                    );
                })}
            </div>
        );
    };

    const handleLoadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    return (
        <div className="reviews">
            <h3>User Reviews</h3>
            {reviews.length === 0 && isAuthenticated && (
                <p>No reviews for this venue. Visited recently? Kickstart their TAB movement and leave one below!</p>
            )}
            {reviews.map((review) => (
                <div key={review._id} className="review-card">
                    <h4>{review.title}</h4>
                    {renderStars(review.rating, () => {})}
                    <p>{review.content}</p>
                    <small>By {review.first_name} {review.last_name} on {new Date(review.created_at).toLocaleDateString()}</small>
                </div>
            ))}
            {reviews.length >= reviewsPerPage * page && (
                <button className="load-more-button" onClick={handleLoadMore}>Load More</button>
            )}
            {isAuthenticated && (
                <form onSubmit={handleSubmit} className="review-form">
                    <h4>Add a Review</h4>
                    <input
                        type="text"
                        name="title"
                        value={newReview.title}
                        onChange={handleInputChange}
                        placeholder="Title"
                        required
                    />
                    {renderStars(newReview.rating, handleStarClick)}
                    <textarea
                        name="content"
                        value={newReview.content}
                        onChange={handleInputChange}
                        placeholder="Your review"
                        required
                    />
                    <button type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit Review'}
                    </button>
                </form>
            )}
        </div>
    );
};

export default Reviews;
