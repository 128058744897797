import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const IsLoggedInRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    if (isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were trying to go to
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }

    return children;
};

export default IsLoggedInRoute; // Ensure this line is correctly exporting the component
