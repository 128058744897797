import React from 'react';
import './Privacy.css';

const Privacy = () => {
    return (
        <div className="privacy-container">
            <h1>Privacy Policy</h1>
            <p>Last updated: 21/05/2024</p>
            <p>Welcome to Time at the Bar's Privacy Policy. We value your privacy and strive to protect your personal information. This Privacy Policy outlines how we handle your personal data when you use our services at www.timeatthebar.co.uk.</p>

            <h2>1. Company Information</h2>
            <p><strong>Company Name:</strong> Time at the Bar</p>
            <p><strong>Website:</strong> <a href="https://www.timeatthebar.co.uk">www.timeatthebar.co.uk</a></p>
            <p><strong>Contact Address:</strong> 86-90 Paul Street, London, EC2A 4NE</p>
            <p><strong>Email:</strong> <a href="mailto:info@timeatthebar.co.uk">info@timeatthebar.co.uk</a></p>
            <p><strong>Phone:</strong> +44 7822013887</p>

            <h2>2. Data Collection</h2>
            <p>We collect various types of personal data from our users to provide and improve our services. The data we collect includes:</p>
            <ul>
                <li><strong>Name:</strong> To identify you and personalize our services.</li>
                <li><strong>Email:</strong> For communication purposes and account management.</li>
                <li><strong>Address:</strong> To provide location-based services and for billing purposes.</li>
                <li><strong>Data collected on the website:</strong> To enhance your user experience and improve our services.</li>
            </ul>
            <p>Data is collected through forms on our website and via Stripe's integrated checkout for payment processing.</p>

            <h2>3. Data Usage</h2>
            <p>The personal data we collect is used for the following purposes:</p>
            <ul>
                <li><strong>Account Management:</strong> To manage your account and provide customer support.</li>
                <li><strong>Marketing:</strong> To send you promotional materials and updates about our services.</li>
                <li><strong>Service Improvement:</strong> To analyze user behavior and improve our offerings.</li>
            </ul>
            <p>We may use the data collected by our users for various reasons, including enhancing our services and conducting market research. User contact information may be sold to interested parties based on their usage on our website, ensuring it is beneficial for all parties involved.</p>

            <h2>4. User Rights</h2>
            <p>We respect your rights regarding your personal data. You have the following rights:</p>
            <ul>
                <li><strong>Access:</strong> You can request a copy of the personal data we hold about you.</li>
                <li><strong>Correction:</strong> You can request to correct any inaccurate or incomplete data.</li>
                <li><strong>Deletion:</strong> You can request to delete your personal data.</li>
            </ul>
            <p>Users can exercise these rights by accessing their account page at <a href="https://www.timeatthebar.co.uk/my-account">www.timeatthebar.co.uk/my-account</a>. To delete your account, please ensure you check the box stating that you wish for your information to be deleted; otherwise, your account information will be archived and may still be used for the purposes stated above.</p>

            <h2>5. Data Security</h2>
            <p>We take data security seriously and implement various measures to protect your personal information. These include:</p>
            <ul>
                <li><strong>Encryption:</strong> We use MDHash, Crypto, and Salting to encrypt your data.</li>
                <li><strong>Secure Servers:</strong> Our servers are secured by AtlasMDB to prevent unauthorized access.</li>
            </ul>

            <h2>6. Cookies and Tracking</h2>
            <p>We use cookies to manage user sessions and for verification purposes. This helps us ensure that users are not using bots to access our website and enhances your overall user experience.</p>

            <h2>7. Changes to Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be notified to users on their dashboard. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your personal information.</p>

            <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@timeatthebar.co.uk">info@timeatthebar.co.uk</a>.</p>
        </div>
    );
};

export default Privacy;
