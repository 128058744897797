import React from 'react';
import './Sidebar.css';

const Sidebar = ({ setActiveTab }) => {
    return (
        <div className="sidebar">
            <button onClick={() => setActiveTab('General')}>General</button>
            <button onClick={() => setActiveTab('Account Settings')}>Account Settings</button>
            <button onClick={() => setActiveTab('Preferences')}>Preferences</button>
            <button onClick={() => setActiveTab('Friends')}>Friends</button>
        </div>
    );
};

export default Sidebar;