import React from 'react';
import './AutomatedBrowser.css';

const AutomatedBrowser = () => {
    return (
        <div className="automated-browser-container">
            <div className="warning-icon">
                <i className="fa fa-exclamation-triangle"></i>
            </div>
            <h1>Warning: Automated Browsing Detected</h1>
            <p>It appears that you are using automated browsing techniques, such as web scraping, on our website.</p>
            <p>Continued use of these techniques can cause significant damage, including:</p>
            <ul>
                <li>Overloading our servers and disrupting services for other users.</li>
                <li>Compromising the security and integrity of our data.</li>
                <li>Violating our terms of service.</li>
                <li>Not being very friendly 😔</li>
            </ul>
            <p>If you continue to use automated browsing techniques, your IP address may be suspended from accessing our website.</p>
            <p>We value our users and aim to provide a fair and secure environment for everyone. Please stop using automated browsing techniques immediately.</p>
            <hr />
            <p>If you are here to test out your automation skills however... here is a table for you:</p>
            <table>
                <thead>
                    <tr>
                        <th>
                            Fruit
                        </th>
                        <th>
                            Price
                        </th>
                        <th>
                            Colour
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Apple
                        </td>
                        <td>
                            £0.67
                        </td>
                        <td>
                            Red/Green
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Orange
                        </td>
                        <td>
                            £0.87
                        </td>
                        <td>
                            Orange
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Pear
                        </td>
                        <td>
                            £0.42
                        </td>
                        <td>
                            Yellow/Green
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Banana
                        </td>
                        <td>
                            £0.52
                        </td>
                        <td>
                            Yellow/Green
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Mango
                        </td>
                        <td>
                            £1.29
                        </td>
                        <td>
                            Yellow
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AutomatedBrowser;
