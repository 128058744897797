import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Login.css';

const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
        twoFactorCode: ''
    });
    const [rememberMe, setRememberMe] = useState(false);
    const [rememberDuration, setRememberDuration] = useState('24h');
    const [loginPhase, setLoginPhase] = useState('initial'); // 'initial', '2FA', 'success'
    const [error, setError] = useState('');

    const handleInputChange = (event) => {
        setCredentials({
            ...credentials,
            [event.target.name]: event.target.value
        });
    };

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    const handleDurationChange = (event) => {
        setRememberDuration(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        const endpoint = 'https://api.timeatthebar.co.uk/login';

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...credentials, rememberDuration })
        });

        const data = await response.json();

        if (response) {
            if (data.message === '2FA token required') {
                setLoginPhase('2FA');
            } else if (data.message === 'User not found') {
                setLoginPhase('NotFound');
            } else if (data.message === 'Invalid credentials') {
                setLoginPhase('WrongPass');
            } else if (data.message === '2FA token is invalid') {
                setLoginPhase('2FA-Invalid');
            } else {
                login(data.session_token, data.user_id);
                navigate('/dashboard');
            }
        } else {
            setError(data.error || 'An error occurred');
        }
    };

    return (
        <div className="login-container">
            {loginPhase.includes('2FA') && (
                <form className='login-form' onSubmit={handleSubmit}>
                    <label>
                        Two Factor Code:
                        <input
                            type="text"
                            name="token"
                            value={credentials.token}
                            onChange={handleInputChange}
                            className="input-field"
                        />
                    </label>
                    <button type="submit" className="login-button">Login</button>
                    {loginPhase.includes('Invalid') && (
                        <p className="error-message">2FA Token Invalid - Please Try Again</p>
                    )}
                </form>
            )}
            {loginPhase !== '2FA' && (
                <form className='login-form' onSubmit={handleSubmit}>
                    {loginPhase === 'initial' && (
                        <>
                            <label>
                                Username/Email:
                                <input
                                    type="text"
                                    name="username"
                                    value={credentials.username}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Password:
                                <input
                                    type="password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label className="remember-me">
                                <input
                                    type="checkbox"
                                    checked={rememberMe}
                                    onChange={handleRememberMeChange}
                                />
                                Remember me
                            </label>
                            {rememberMe && (
                                <div className="remember-options">
                                    <label>
                                        <input
                                            type="radio"
                                            value="24h"
                                            checked={rememberDuration === '24h'}
                                            onChange={handleDurationChange}
                                        />
                                        1 Day
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="72h"
                                            checked={rememberDuration === '72h'}
                                            onChange={handleDurationChange}
                                        />
                                        3 Days
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="168h"
                                            checked={rememberDuration === '168h'}
                                            onChange={handleDurationChange}
                                        />
                                        1 Week
                                    </label>
                                </div>
                            )}
                        </>
                    )}
                    {loginPhase === 'NotFound' && (
                        <>
                            <label>
                                Username/Email:
                                <input
                                    type="text"
                                    name="username"
                                    value={credentials.username}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Password:
                                <input
                                    type="password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <p className="error-message">User doesn't exist</p>
                        </>
                    )}
                    {loginPhase === 'WrongPass' && (
                        <>
                            <label>
                                Username/Email:
                                <input
                                    type="text"
                                    name="username"
                                    value={credentials.username}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Password:
                                <input
                                    type="password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <p className="error-message">Password Incorrect</p>
                        </>
                    )}
                    <button type="submit" className="login-button">Login</button>
                </form>
            )}
            <p>Don't have an account? <a href="/register" className="link">Sign up here</a></p>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default Login;
