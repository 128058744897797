import React from 'react';
import './Dashboard.css';

const Dashboard = () => {
    return (
        <div className="dashboard-container">
            <h1>Welcome to your dashboard</h1>
        </div>
    );
};

export default Dashboard;
