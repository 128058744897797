import React, { useState, useEffect } from 'react';
import './Preferences.css';

const PreferencesTab = ({ userId }) => {
    const [preferences, setPreferences] = useState({
        beerGarden: false,
        dogFriendly: false,
        liveMusic: false,
        poolTable: false,
        pubFood: false,
        realAle: false,
        darts: false,
        disabledAccess: false,
        pubQuiz: false,
        sundayRoast: false,
        btSports: false,
        skySports: false,
        parking: false,
        accommodation: false,
        nearCampsite: false,
        scenic: false,
        cocktails: false,
        playArea: false,
        functionRoom: false,
        takeawayAvailable: false,
        glutenFreeFood: false,
        vegan: false,
        vegetarian: false,
        nearbyStation: false,
        caskAle: false,
        lgbtqPlus: false,
        studentOffers: false,
        microPub: false,
        happyHour: false,
        boardGames: false
    });

    useEffect(() => {
        const fetchUserPreferences = async () => {
            try {
                const response = await fetch('https://api.timeatthebar.co.uk/getUserPreferences', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId })
                });
                const data = await response.json();
                setPreferences(data.preferences);
            } catch (error) {
                console.error('Error fetching user preferences:', error);
            }
        };

        if (userId) {
            fetchUserPreferences();
        }
    }, [userId]);

    const handleInputChange = (event) => {
        setPreferences({
            ...preferences,
            [event.target.name]: event.target.checked
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('https://api.timeatthebar.co.uk/updateUserPreferences', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, preferences })
            });

            const data = await response.json();
            if (data.result === 'success') {
                alert('Preferences updated successfully!');
            } else {
                console.error('Failed to update preferences:', data);
            }
        } catch (error) {
            console.error('Error updating preferences:', error);
        }
    };

    return (
        <div className="preferences-container">
            <h2>Set Your Preferences</h2>
            <form onSubmit={handleSubmit} className="preferences-form">
                {Object.keys(preferences).map((key) => (
                    <label key={key}>
                        <input
                            type="checkbox"
                            name={key}
                            checked={preferences[key]}
                            onChange={handleInputChange}
                        />
                        {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                    </label>
                ))}
                <button type="submit" className="save-button">Save Preferences</button>
            </form>
        </div>
    );
};

export default PreferencesTab;
