import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Sidebar from '../components/Sidebar';
import GeneralInfo from '../components/GeneralInfo';
import AccountSettings from '../components/AccountSettings';
import PreferencesTab from '../components/PreferencesTab';
import FriendsTab from '../components/FriendsTab'; // Import FriendsTab
import './MyAccount.css';

const MyAccount = () => {
    const { logout } = useAuth();
    const [userId, setUserId] = useState('');
    const [activeTab, setActiveTab] = useState('General');
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        phone: '',
        website: '',
        streetAddress: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        gender: '',
        firstName: '',
        lastName: '',
        preferences: {
            beerGarden: false,
            dogFriendly: false
        }
    });

    useEffect(() => {
        const fetchUserData = async (storedUserId) => {
            try {
                const response = await fetch('https://api.timeatthebar.co.uk/getUser', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: storedUserId })
                });
                const userDataRes = await response.json();
                setUserData(userDataRes.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
            fetchUserData(storedUserId);
        }
    }, []);

    const handleUpdatePreferences = async (newPreferences) => {
        try {
            const response = await fetch('https://api.timeatthebar.co.uk/updateUserPreferences', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, preferences: newPreferences })
            });
            const data = await response.json();
            if (data.result === 'success') {
                setUserData({ ...userData, preferences: newPreferences });
            } else {
                console.error('Failed to update preferences:', data);
            }
        } catch (error) {
            console.error('Error updating preferences:', error);
        }
    };

    return (
        <div className="my-account-container">
            <Sidebar setActiveTab={setActiveTab} />
            <div className="content">
                {activeTab === 'General' && (
                    <GeneralInfo userData={userData} onUpdatePreferences={handleUpdatePreferences} />
                )}
                {activeTab === 'Account Settings' && (
                    <AccountSettings userId={userId} logout={logout} />
                )}
                {activeTab === 'Preferences' && (
                    <PreferencesTab userId={userId} />
                )}
                {activeTab === 'Friends' && (
                    <FriendsTab userId={userId} />
                )}
            </div>
        </div>
    );
};

export default MyAccount;
