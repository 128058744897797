import React from 'react';
import './Loading.css';

function Loading() {
    return (
        <div className='loading-area'>
            <div className="loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </div>
    );
}

export default Loading;
