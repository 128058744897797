import React from 'react';
import PropTypes from 'prop-types';
import './TestimonialCard.css';

const TestimonialCard = ({ quote, name, position }) => {
    return (
        <div className="testimonial-card">
            <p>"{quote}"</p>
            <h4>{name}</h4>
            <h5>{position}</h5>
        </div>
    );
};

TestimonialCard.propTypes = {
    quote: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
};

export default TestimonialCard;
