import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Reviews from '../components/Reviews';
import './VenueDetail.css';
import defaultImage from '../assets/default.png';

const VenueDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [venue, setVenue] = useState(null);
    const { userRole, isAuthenticated } = useAuth();

    useEffect(() => {
        const fetchVenue = async () => {
            const response = await fetch(`https://api.timeatthebar.co.uk/get-venue/${id}`);
            const data = await response.json();
            setVenue(data.venue);
        };
        fetchVenue();
    }, [id]);

    const handleClaim = () => {
        if (userRole === 'guest') {
            navigate('/register', { state: { from: `/claim/${id}` } });
        } else {
            navigate(`/claim/${id}`);
        }
    };

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    if (!venue) return <p>Loading...</p>;

    const hasContactInfo = venue.phone || venue.email || venue.website;
    const hasSocials = venue.facebook || venue.instagram;

    return (
        <div className="venue-detail">
            <button className="back-button" onClick={handleBack}>Back</button>
            <div className="venue-section card">
                <h1>{venue.title}</h1>
                <img
                    src={venue.images && venue.images.length > 0 ? `data:image/jpeg;base64,${venue.images[0]}` : defaultImage}
                    alt={venue.title}
                    className="venue-image"
                />
                <p>{venue.description}</p>
            </div>
            {hasContactInfo && (
                <div className="venue-section card">
                    <h2>Contact Info</h2>
                    {venue.phone && (
                        <div className="info-card">
                            <i className="fas fa-phone"></i>
                            <p><a href={`tel:${venue.phone}`}>{venue.phone}</a></p>
                        </div>
                    )}
                    {venue.email && (
                        <div className="info-card">
                            <i className="fas fa-envelope"></i>
                            <p><a href={`mailto:${venue.email}`}>{venue.email}</a></p>
                        </div>
                    )}
                    {venue.website && (
                        <div className="info-card">
                            <i className="fas fa-globe"></i>
                            <p><a href={venue.website} target="_blank" rel="noopener noreferrer">{venue.website}</a></p>
                        </div>
                    )}
                </div>
            )}
            {hasSocials && (
                <div className="venue-section card">
                    <h2>Socials</h2>
                    {venue.facebook && (
                        <div className="info-card">
                            <i className="fab fa-facebook"></i>
                            <p><a href={venue.facebook} target="_blank" rel="noopener noreferrer">{venue.facebook}</a></p>
                        </div>
                    )}
                    {venue.instagram && (
                        <div className="info-card">
                            <i className="fab fa-instagram"></i>
                            <p><a href={venue.instagram} target="_blank" rel="noopener noreferrer">{venue.instagram}</a></p>
                        </div>
                    )}
                </div>
            )}
            <div className="venue-section card">
                <h2>Location</h2>
                <div className="info-card">
                    <i className="fas fa-map-marker-alt"></i>
                    <p>{venue.street}, {venue.city}, {venue.county}, {venue.postcode}, {venue.country}</p>
                </div>
            </div>
            <div className="venue-section card">
                <h2>Reviews</h2>
                <Reviews venueId={id} />
            </div>
            {isAuthenticated && (
                <button className="claim-button" onClick={handleClaim}>
                    {userRole === 'guest' ? 'Register to Claim' : 'Claim this Venue'}
                </button>
            )}
        </div>
    );
};

export default VenueDetail;
