import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AutomatedBrowserCheck = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const calculateScore = () => {
            let score = 0;

            // Headless Browser Checks
            const isHeadlessChrome = navigator.userAgent.indexOf("HeadlessChrome") !== -1;
            const isWebdriver = navigator.webdriver;
            const isChromeWebstoreNull = window.chrome && window.chrome.webstore === null;
            const noPlugins = window.navigator.plugins.length === 0;
            const headlessFeatures = window.outerWidth === 0 || window.outerHeight === 0;
            const userAgentHeadless = navigator.userAgent.toLowerCase().includes('headless') || navigator.userAgent.toLowerCase().includes('phantom');

            if (isHeadlessChrome) score += 3;
            if (isWebdriver) score += 3;
            if (isChromeWebstoreNull) score += 2;
            if (noPlugins) score += 2;
            if (headlessFeatures) score += 3;
            if (userAgentHeadless) score += 3;

            // Selenium Check
            const isSelenium = () => {
                for (let key in window) {
                    if (key.match(/^(?:__|webdriver|domAutomation|domAutomationController|driver)/)) {
                        return true;
                    }
                }
                return false;
            };
            if (isSelenium()) score += 3;

            // Puppeteer Check
            const isPuppeteer = navigator.userAgent.includes('Puppeteer') || navigator.vendor === 'HeadlessChrome';
            if (isPuppeteer) score += 3;

            // General Bot Checks
            const isEmptyLanguages = navigator.languages === '';
            const isLighthouse = navigator.userAgent.indexOf('Chrome-Lighthouse') > -1;

            if (isEmptyLanguages) score += 2;
            if (isLighthouse) score += 2;

            // Device Checks
            const isMobile = /Mobi|Android/i.test(navigator.userAgent);
            const isTablet = /Tablet|iPad/i.test(navigator.userAgent);

            if (isMobile) score -= 1;
            if (isTablet) score -= 1;

            return score;
        };

        const score = calculateScore();
        const threshold = 5;

        if (score >= threshold) {
            navigate('/automated-browser');
        }
    }, [navigate]);

    return null;
};

export default AutomatedBrowserCheck;
