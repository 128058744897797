import React from 'react';
import './Home.css';
import FeatureBlock from '../components/FeatureBlock';
import PricingCard from '../components/PricingCard';
import TestimonialCarousel from '../components/TestimonialCarousel';
import CTASection from '../components/CTASection';

const Home = () => {

    return (
        <div className="home-container">
            <header className="home-header">
                <div className="welcome">
                    <h1>Welcome to Time at the Bar</h1>
                    <p>Your Virtual Pub Guide.</p>
                </div>
            </header>
            <section className="features-section">
                <h2>Our Key Features</h2>
                <div className="features-grid">
                    <FeatureBlock
                        icon="fa-beer"
                        title="Venue Directory"
                        body="Explore over 40,000 active venues with bars across the UK."
                    />
                    <FeatureBlock
                        icon="fa-map-marker-alt"
                        title="Location-Based Search"
                        body="Find bars and venues near you with our advanced search."
                    />
                    <FeatureBlock
                        icon="fa-star"
                        title="User Reviews"
                        body="Read and write reviews for your favorite spots."
                    />
                    <FeatureBlock
                        icon="fa-calendar-alt"
                        title="Events"
                        body="Stay updated on the latest events happening at bars near you."
                    />
                    <FeatureBlock
                        icon="fa-thumbs-up"
                        title="Recommendations"
                        body="Get personalized bar recommendations based on your preferences."
                    />
                    <FeatureBlock
                        icon="fa-mobile-alt"
                        title="Mobile Friendly"
                        body="Access our platform on any device, anytime, anywhere."
                    />
                </div>
            </section>
            <section className="testimonials-section">
                <h2>What Our Users Say</h2>
                <TestimonialCarousel/>
            </section>
            <CTASection />
        </div>
    );
};

export default Home;
