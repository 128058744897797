import React from 'react';
import './CTASection.css';

const CTASection = () => {
    return (
        <section className="cta-section">
            <h2>Ready to Get Started?</h2>
            <p>Join Data Menu today and take your data management to the next level!</p>
            <button>Sign Up Now</button>
        </section>
    );
};

export default CTASection;
