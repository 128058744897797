import React, { useEffect } from 'react';

const Pricing = () => {
    useEffect(() => {
        // Function to load the Stripe Pricing Table script
        const loadStripePricingTable = () => {
            if (!document.querySelector('#stripe-pricing-table-script')) {
                const script = document.createElement('script');
                script.id = 'stripe-pricing-table-script';
                script.src = 'https://js.stripe.com/v3/pricing-table.js';
                script.async = true;
                document.body.appendChild(script);
            }
        };

        loadStripePricingTable();
    }, []);

    return (
        <div>
            {/* Stripe Pricing Table Element */}
            <stripe-pricing-table
                pricing-table-id="prctbl_1PDQEOGBQeIDfgAMnsLVtIoq"
                publishable-key="pk_test_51PAlIQGBQeIDfgAMvMpSqNrDsb08xvZdPLSHW24SFTI7NtbAQHahUb8xqKbfnqEMCEjGvkD8qbPlPQJVFADAEl1y00u6wJHswb">
            </stripe-pricing-table>
        </div>
    );
};

export default Pricing;
