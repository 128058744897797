import React from 'react';
import './About.css';
import ContactForm from '../components/ContactForm';

const About = () => {
    return (
        <div className="about-container">
            <header className="about-header">
                <h1>About Time at the Bar</h1>
                <p>Welcome to Time at the Bar, your ultimate guide to the best bars and venues in the UK.</p>
            </header>

            <section className="mission-vision">
                <div className="mission">
                    <h2>Our Mission</h2>
                    <p>At Time at the Bar, our mission is to help you discover the best bars and venues across the UK. We aim to provide an easy-to-use platform where users can find and explore venues, read reviews, and stay updated on the latest events.</p>
                </div>
                <div className="vision">
                    <h2>Our Vision</h2>
                    <p>Our vision is to be the leading directory for bar venues in the UK, continually enhancing our platform based on user feedback. We aim to provide the most comprehensive and up-to-date information, making it easier for users to find their next favorite spot.</p>
                </div>
            </section>

            <section className="history">
                <h2>Our History</h2>
                <p>Founded in May 2024, Time at the Bar quickly grew to become the largest directory of venues with bars in the UK. With over 40,000 active venues and 20+ features, we are committed to providing a robust and user-friendly platform for all bar enthusiasts.</p>
            </section>

            <section className="services">
                <h2>Our Services</h2>
                <ul>
                    <li>Venue Directory: Explore a vast database of bars and venues.</li>
                    <li>Event Listings: Stay updated on the latest events happening near you.</li>
                    <li>User Reviews: Read and write reviews for your favorite spots.</li>
                    <li>Personalized Recommendations: Get suggestions based on your preferences.</li>
                    <li>Mobile Friendly: Access our platform on any device.</li>
                </ul>
            </section>

            <section className="suggestions">
                <h2>Have a Suggestion?</h2>
                <p>We encourage our users to suggest new features or venues they would like to see. Use the form below to submit your suggestions:</p>
                <ContactForm />
            </section>
        </div>
    );
};

export default About;
