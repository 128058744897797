import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import './Listings.css';
import Loading from '../components/Loading';
import Filters from '../components/Filters';
import defaultImage from '../assets/default.png';

// Import feature images
import beerGardenImage from '../assets/icons/standard/beerGarden.png';
import beerGardenPrefImage from '../assets/icons/animation/beerGarden.png';
import dogFriendlyImage from '../assets/icons/standard/dogFriendly.png';
import dogFriendlyPrefImage from '../assets/icons/animation/dogFriendly.png';
import liveMusicImage from '../assets/icons/standard/liveMusic.png';
import liveMusicPrefImage from '../assets/icons/animation/liveMusic.png';
import poolTableImage from '../assets/icons/standard/poolTable.png';
import poolTablePrefImage from '../assets/icons/animation/poolTable.png';
import pubFoodImage from '../assets/icons/standard/pubFood.png';
import pubFoodPrefImage from '../assets/icons/animation/pubFood.png';
import realAleImage from '../assets/icons/standard/realAle.png';
import realAlePrefImage from '../assets/icons/animation/realAle.png';
import dartsImage from '../assets/icons/standard/darts.png';
import dartsPrefImage from '../assets/icons/animation/darts.png';
import disabledAccessImage from '../assets/icons/standard/disabledAccess.png';
import disabledAccessPrefImage from '../assets/icons/animation/disabledAccess.png';
import pubQuizImage from '../assets/icons/standard/pubQuiz.png';
import pubQuizPrefImage from '../assets/icons/animation/pubQuiz.png';
import sundayRoastImage from '../assets/icons/standard/sundayRoast.png';
import sundayRoastPrefImage from '../assets/icons/animation/sundayRoast.png';
import btSportsImage from '../assets/icons/standard/btSports.png';
import btSportsPrefImage from '../assets/icons/animation/btSports.png';
import skySportsImage from '../assets/icons/standard/skySports.png';
import skySportsPrefImage from '../assets/icons/animation/skySports.png';
import parkingImage from '../assets/icons/standard/parking.png';
import parkingPrefImage from '../assets/icons/animation/parking.png';
import accommodationImage from '../assets/icons/standard/accommodation.png';
import accommodationPrefImage from '../assets/icons/animation/accommodation.png';
import nearCampsiteImage from '../assets/icons/standard/nearCampsite.png';
import nearCampsitePrefImage from '../assets/icons/animation/nearCampsite.png';
import scenicImage from '../assets/icons/standard/scenic.png';
import scenicPrefImage from '../assets/icons/animation/scenic.png';
import cocktailsImage from '../assets/icons/standard/cocktails.png';
import cocktailsPrefImage from '../assets/icons/animation/cocktails.png';
import playAreaImage from '../assets/icons/standard/playArea.png';
import playAreaPrefImage from '../assets/icons/animation/playArea.png';
import functionRoomImage from '../assets/icons/standard/functionRoom.png';
import functionRoomPrefImage from '../assets/icons/animation/functionRoom.png';
import takeawayAvailableImage from '../assets/icons/standard/takeawayAvailable.png';
import takeawayAvailablePrefImage from '../assets/icons/animation/takeawayAvailable.png';
import glutenFreeFoodImage from '../assets/icons/standard/glutenFreeFood.png';
import glutenFreeFoodPrefImage from '../assets/icons/animation/glutenFreeFood.png';
import veganImage from '../assets/icons/standard/vegan.png';
import veganPrefImage from '../assets/icons/animation/vegan.png';
import vegetarianImage from '../assets/icons/standard/vegetarian.png';
import vegetarianPrefImage from '../assets/icons/animation/vegetarian.png';
import nearbyStationImage from '../assets/icons/standard/nearbyStation.png';
import nearbyStationPrefImage from '../assets/icons/animation/nearbyStation.png';
import caskAleImage from '../assets/icons/standard/caskAle.png';
import caskAlePrefImage from '../assets/icons/animation/caskAle.png';
import lgbtqPlusImage from '../assets/icons/standard/lgbtqPlus.png';
import lgbtqPlusPrefImage from '../assets/icons/animation/lgbtqPlus.png';
import studentOffersImage from '../assets/icons/standard/studentOffers.png';
import studentOffersPrefImage from '../assets/icons/animation/studentOffers.png';
import microPubImage from '../assets/icons/standard/microPub.png';
import microPubPrefImage from '../assets/icons/animation/microPub.png';
import happyHourImage from '../assets/icons/standard/happyHour.png';
import happyHourPrefImage from '../assets/icons/animation/happyHour.png';
import boardGamesImage from '../assets/icons/standard/boardGames.png';
import boardGamesPrefImage from '../assets/icons/animation/boardGames.png';

const featureImages = {
    beerGarden: { standard: beerGardenImage, pref: beerGardenPrefImage },
    dogFriendly: { standard: dogFriendlyImage, pref: dogFriendlyPrefImage },
    liveMusic: { standard: liveMusicImage, pref: liveMusicPrefImage },
    poolTable: { standard: poolTableImage, pref: poolTablePrefImage },
    pubFood: { standard: pubFoodImage, pref: pubFoodPrefImage },
    realAle: { standard: realAleImage, pref: realAlePrefImage },
    darts: { standard: dartsImage, pref: dartsPrefImage },
    disabledAccess: { standard: disabledAccessImage, pref: disabledAccessPrefImage },
    pubQuiz: { standard: pubQuizImage, pref: pubQuizPrefImage },
    sundayRoast: { standard: sundayRoastImage, pref: sundayRoastPrefImage },
    btSports: { standard: btSportsImage, pref: btSportsPrefImage },
    skySports: { standard: skySportsImage, pref: skySportsPrefImage },
    parking: { standard: parkingImage, pref: parkingPrefImage },
    accommodation: { standard: accommodationImage, pref: accommodationPrefImage },
    nearCampsite: { standard: nearCampsiteImage, pref: nearCampsitePrefImage },
    scenic: { standard: scenicImage, pref: scenicPrefImage },
    cocktails: { standard: cocktailsImage, pref: cocktailsPrefImage },
    playArea: { standard: playAreaImage, pref: playAreaPrefImage },
    functionRoom: { standard: functionRoomImage, pref: functionRoomPrefImage },
    takeawayAvailable: { standard: takeawayAvailableImage, pref: takeawayAvailablePrefImage },
    glutenFreeFood: { standard: glutenFreeFoodImage, pref: glutenFreeFoodPrefImage },
    vegan: { standard: veganImage, pref: veganPrefImage },
    vegetarian: { standard: vegetarianImage, pref: vegetarianPrefImage },
    nearbyStation: { standard: nearbyStationImage, pref: nearbyStationPrefImage },
    caskAle: { standard: caskAleImage, pref: caskAlePrefImage },
    lgbtqPlus: { standard: lgbtqPlusImage, pref: lgbtqPlusPrefImage },
    studentOffers: { standard: studentOffersImage, pref: studentOffersPrefImage },
    microPub: { standard: microPubImage, pref: microPubPrefImage },
    happyHour: { standard: happyHourImage, pref: happyHourPrefImage },
    boardGames: { standard: boardGamesImage, pref: boardGamesPrefImage },
};

const Listings = () => {
    const [venues, setVenues] = useState([]);
    const [filteredVenues, setFilteredVenues] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [userLocation, setUserLocation] = useState(null);
    const { user, isAuthenticated } = useAuth();
    const venuesPerPage = 5;
    const navigate = useNavigate();
    const [friends, setFriends] = useState([]);
    const [currentDrinkingVenue, setCurrentDrinkingVenue] = useState(null);
    const [currentFavourites, setCurrentFavourites] = useState([]);

    useEffect(() => {
        const fetchUserLocation = () => {
            navigator.geolocation.getCurrentPosition((position) => {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
                console.log('User location:', position.coords);
            }, (error) => {
                console.error("Error fetching user's location:", error);
            });
        };
        fetchUserLocation();
    }, []);

    useEffect(() => {
        if (userLocation && user) {
            fetchVenues(userLocation);
            fetchFriends();
            fetchFavorites();
        } else {
            console.log('User Not Found');
        }
    }, [userLocation, user]);

    useEffect(() => {
        if (venues.length > 0) {
            setFilteredVenues(venues.slice((page - 1) * venuesPerPage, page * venuesPerPage));
        }
    }, [venues, page]);

    const fetchVenues = async (coords, features = {}, radius = 10) => {
        if (!coords) return;

        setLoading(true);
        try {
            console.log('Fetching venues...');
            const response = await fetch(`https://api.timeatthebar.co.uk/get-venues?latitude=${coords.latitude}&longitude=${coords.longitude}&radius=${radius}&features=${JSON.stringify(features)}&limit=100`);
            const data = await response.json();
            if (data.venues) {
                const sortedVenues = sortVenuesByDistance(data.venues, coords);
                setVenues(sortedVenues);
                console.log('Fetched venues:', sortedVenues);
            } else {
                console.error('No venues found');
            }
        } catch (error) {
            console.error('Error fetching venues:', error);
        } finally {
            setLoading(false);
            console.log('Finished fetching venues, loading set to false');
        }
    };

    const fetchFavorites = async () => {
        if (!user) {
            console.log('No user found')
            return;
        }
        try {
            const response = await fetch(`https://api.timeatthebar.co.uk/favourites/${user._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            console.log(data);
            if (data.favourites) {
                setVenues(prevVenues => {
                    const updatedVenues = prevVenues.map(venue => ({
                        ...venue,
                        isFavourite: data.favorites.includes(venue._id)
                    }));
                    setFilteredVenues(updatedVenues.slice((page - 1) * venuesPerPage, page * venuesPerPage));
                    return updatedVenues;
                });

                console.log(data.favourites);
            }
        } catch (error) {
            console.error('Error fetching favorites:', error);
        }
    };

    const fetchFriends = async () => {
        if (!user) return;

        try {
            const response = await fetch('https://api.timeatthebar.co.uk/getFriends', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user._id })
            });
            const data = await response.json();
            setFriends(data.friends);
        } catch (error) {
            console.error('Error fetching friends:', error);
        }
    };

    const sortVenuesByDistance = (venues, userCoords) => {
        return venues.map(venue => ({
            ...venue,
            distance: calculateDistance(userCoords, {
                latitude: venue.latitude,
                longitude: venue.longitude
            })
        })).sort((a, b) => a.distance - b.distance);
    };

    const calculateDistance = (loc1, loc2) => {
        const R = 6371; // Radius of the Earth in km
        const dLat = toRad(loc2.latitude - loc1.latitude);
        const dLon = toRad(loc2.longitude - loc1.longitude);
        const lat1 = toRad(loc1.latitude);
        const lat2 = toRad(loc2.latitude);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distanceInKm = R * c;
        return distanceInKm * 0.621371; // Convert to miles
    };

    const toRad = (value) => {
        return value * Math.PI / 180;
    };

    const handleFavourite = async (venueId, e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!isAuthenticated) {
            alert("Please log in to save favourites.");
            navigate('/login');  // Redirect to login page
            return;
        }

        if (!user) {
            console.error('User is not defined');
            return;
        }

        const isFavourite = venues.find(venue => venue._id === venueId).isFavourite;
        const url = isFavourite ? 'https://api.timeatthebar.co.uk/remove-favourite' : 'https://api.timeatthebar.co.uk/add-favourite';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId: user._id, venueId })
            });

            if (response.ok) {
                setVenues((prevVenues) => {
                    const updatedVenues = prevVenues.map((venue) => {
                        if (venue._id === venueId) {
                            return {
                                ...venue,
                                isFavourite: !isFavourite
                            };
                        }
                        return venue;
                    });
                    setFilteredVenues(updatedVenues.slice((page - 1) * venuesPerPage, page * venuesPerPage));
                    return updatedVenues;
                });
                console.log('Favourite updated successfully');
            } else {
                console.error('Failed to update favourite');
            }
        } catch (error) {
            console.error('Error updating favourite:', error);
        }
    };

    const handleDrinkingAt = async (venueId, e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!user) return;

        try {
            const response = await fetch('https://api.timeatthebar.co.uk/updateDrinkingAt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user._id, venueId })
            });
            const data = await response.json();
            if (data.message === 'Drinking status updated') {
                setVenues((prevVenues) => {
                    const updatedVenues = prevVenues.map((venue) => {
                        if (venue._id === venueId) {
                            return {
                                ...venue,
                                isDrinking: true
                            };
                        } else {
                            return {
                                ...venue,
                                isDrinking: false
                            };
                        }
                    });
                    setFilteredVenues(updatedVenues.slice((page - 1) * venuesPerPage, page * venuesPerPage));
                    return updatedVenues;
                });
                fetchFriends(); // Refresh friends list to show updated drinking status
            }
        } catch (error) {
            console.error('Error updating drinking status:', error);
        }
    };

    const handleFilter = ({ locationCoords, features, radius }) => {
        if (locationCoords) {
            setPage(1); // Reset to first page
            fetchVenues(locationCoords, features, radius);
        }
    };

    const renderFeatureIcons = (venue) => {
        const featureKeys = Object.keys(venue.features);
        return featureKeys.map((key) => {
            if (venue.features[key]) {
                const imageSrc = user && user.preferences[key] ? featureImages[key].pref : featureImages[key].standard;
                return (
                    <img
                        key={key}
                        src={imageSrc}
                        alt={key}
                        title={key}
                        className={`feature-icon ${user && user.preferences[key] ? 'preference' : ''}`}
                        onError={(e) => { e.target.onerror = null; e.target.src = featureImages[key].standard; }}
                    />
                );
            }
            return null;
        });
    };

    const renderFriendsDrinkingHere = (venueId) => {
        if (!user) {
            return ''
        }
        const friendsDrinkingHere = friends.filter(friend => friend.drinkingAt === venueId);

        if (friendsDrinkingHere.length === 0 && user.drinkingAt !== venueId) return null;

        if (user.drinkingAt === venueId && friendsDrinkingHere.length === 0) {
            return (
                <div className="friends-drinking-here">
                    <i className="fa fa-beer"></i>
                    <span>You are drinking here</span>
                </div>
            );
        }

        if (user.drinkingAt === venueId && friendsDrinkingHere.length === 1) {
            return (
                <div className="friends-drinking-here">
                    <i className="fa fa-beer"></i>
                    <span>You & {friendsDrinkingHere[0].firstName} are drinking here</span>
                </div>
            );
        }

        if (user.drinkingAt === venueId && friendsDrinkingHere.length > 1) {
            return (
                <div className="friends-drinking-here">
                    <i className="fa fa-beer"></i>
                    <span>You & {friendsDrinkingHere.length} friends are drinking here</span>
                </div>
            );
        }

        if (friendsDrinkingHere.length === 1) {
            return (
                <div className="friends-drinking-here">
                    <i className="fa fa-user"></i>
                    <span>{friendsDrinkingHere[0].firstName} is drinking here</span>
                </div>
            );
        }

        return (
            <div className="friends-drinking-here">
                <i className="fa fa-users"></i>
                <span>{friendsDrinkingHere.length} friends are drinking here</span>
            </div>
        );
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(venues.length / venuesPerPage);
        return (
            <div className="pagination-container">
                {page > 1 && (
                    <button className="pagination-button" onClick={() => setPage(page - 1)}>
                        <i className="fa fa-chevron-left"></i>
                    </button>
                )}
                <span className="pagination-page">{page}</span>
                {page < totalPages && (
                    <button className="pagination-button" onClick={() => setPage(page + 1)}>
                        <i className="fa fa-chevron-right"></i>
                    </button>
                )}
            </div>
        );
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="listings-container">
            <Filters onFilter={handleFilter} />
            <div className="venues-list">
                {filteredVenues.map((venue) => (
                    <div key={venue._id} className="venue-card">
                        <Link to={`/venue/${venue._id}`}>
                            <div className="image-container">
                                <img
                                    src={venue.images.length > 0 ? `data:image/png;base64,${venue.images[0]}` : defaultImage}
                                    alt={venue.title}
                                    className="venue-image"
                                />
                                <div className="distance-card">
                                    <span>{venue.distance.toFixed(2)} miles</span>
                                </div>
                                <button className={`favourite-button ${venue.isFavourite ? 'favourite' : ''}`} onClick={(e) => handleFavourite(venue._id, e)}>
                                    <i className="fa fa-heart" />
                                </button>
                                <button className={`drinking-at-button ${venue.isDrinking ? 'drinking' : ''}`} onClick={(e) => handleDrinkingAt(venue._id, e)}>
                                    <i className="fa fa-beer" />
                                </button>
                            </div>
                        </Link>
                        <div className="venue-info">
                            <h3>{(venue.title).replace(/&amp;/g, '&')}</h3>
                            <p>{venue.street}, {venue.city}</p>
                            <div className="features">
                                {renderFeatureIcons(venue)}
                            </div>
                            <div className="venue-rating">
                                <div className="stars">
                                    {Array.from({ length: 5 }, (_, i) => (
                                        <span key={i} className={`fa fa-star ${i < Math.round(venue.averageRating) ? 'checked' : ''}`}></span>
                                    ))}
                                </div>
                                <small>({venue.reviewCount})</small>
                            </div>
                            {renderFriendsDrinkingHere(venue._id)}
                        </div>
                    </div>
                ))}
            </div>
            {renderPagination()}
        </div>
    );
};

export default Listings;